<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Create a Topup</p>

        <v-row>
          <input type="hidden" v-model="payment_ref" ref="payment_ref" id="payment_ref">
          <v-col cols="10" class="py-0 my-1">
            <v-text-field
              v-model="userName"
              ref="userName"
              outlined dense
              label="Search username, phone number, unique code..."
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="py-0 my-1">
            <v-btn
              color="success"
              class="me-3"
              fab small
              @click="getTopUps()"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedTopup"
              ref="selectedTopup"
              :items="topups"
              item-text="username"
              item-value="id"
              outlined dense
              label="Select a topup"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-text-field
              v-model="amount"
              ref="amount"
              outlined
              dense
              label="Amount"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <!-- current password -->
            <v-text-field
              v-model="password"
              ref="password"
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="isPasswordVisible ? mdiEyeOffOutline:mdiEyeOutline"
              label="Input Your Password"
              outlined
              dense
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
          </v-col>

        </v-row>
      </v-card-text>

      <v-card-text class="text-right">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn 
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="rechargeTopup()"
        >
          Recharge TopUp
        </v-btn>

      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
// eslint-disable-next-line object-curly-newline
import Spinner from "@/components/Spinner.vue";
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'


export default {
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,
      isPasswordVisible: false,
      password: "",
      userName: '',
      amount: '',
      topups: [],
      password: "",
      payment_ref: "",
      selectedTopup: "",

      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
    }
  },
  mounted(){
    document.title = "Manager: Savings | Recharging Topup"
    this.getRechargePaymentRef()
  },

  methods:{

    async getRechargePaymentRef(){
        await axios
            .get('/api/v1/manager/create/manager/recharge/payment_ref/')
            .then(response => {
                this.payment_ref = response.data
            })
            .catch(error => {
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })
    },

    getTopUps(){
      axios
        .get('/api/v1/manager/get/topups/'+this.userName+'/')
        .then(response => {
          this.topups = response.data  // get the data and fill into campaigns
          console.log(this.users)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async rechargeTopup(){
      if(this.password === ""){
        this.$store.commit('setSnackBarMessage', "Please input your password.")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$refs.password.focus()  // this causes a an auto fucos to the element
      }
      else if(this.selectedTopup === ""){
        this.$store.commit('setSnackBarMessage', "Please select a user.")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$refs.selectedTopup.focus()  // this causes a an auto fucos to the element
      }
      else if(this.amount === ""){
        this.$store.commit('setSnackBarMessage', "Please input amount.")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$refs.amount.focus()  // this causes a an auto fucos to the element
      }
      else if(this.payment_ref === ""){
        this.$store.commit('setSnackBarMessage', "Please reload the page.")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
      }
      else{
        this.loading = true
  
        let formData = new FormData()
        formData.append('selectedTopup', this.selectedTopup)
        formData.append('amount', this.amount)
        formData.append('password', this.password)
        formData.append('payment_ref', this.payment_ref)

        await axios
            .post('/api/v1/manager/recharge/topups/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
                console.log(response.data)
                this.$store.commit('setSnackBarMessage', response.data[0].text)
                this.$store.commit('setSnackBarColor', response.data[0].color)
                this.$store.commit('activateSnackBar', true)
                this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                if(response.data[0].category === "success"){
                  let router = this.$router
                  setTimeout(function(){
                    router.push('/savings/topup/summary')
                  }, 2500)
                }
            })
            .catch(error => {
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })

        this.loading = false
        }

    },

  },
}
</script>
